// @flow
import * as React from 'react'
import type { LinkViewModel } from '../../types/LinkViewModel'
import { Link } from '../../components/Link/Link'
import Ribbon from '../../components/Ribbon/Ribbon'
import Heading from '../../components/Heading/Heading'
import styled from 'styled-components'
import { rem } from 'polished'
import { mediaQuery } from '../../styles/media'

export type Props = {
  label?: string,
  shortCutList?: LinkViewModel[],
}

const StyledRibbon = styled(Ribbon)`
  background-color: #fff;
  margin-bottom: 0;
`

const StyledNav = styled.nav`
  padding: ${rem(43)} 0;
  display: flex;
  flex-direction: column;

  ${mediaQuery.sm`

    flex-direction: row;
    align-items: start;
    gap: ${rem(48)};

  `}
`

const StyledHeading = styled(Heading)`
  ${mediaQuery.sm`
  margin-bottom: 0;
`}
`

const StyledList = styled.ul`
  display: inline-flex;
  gap: ${rem(16)};
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0;
`

const StyledShortcut = styled(Link)`
  padding: ${rem(8)} ${rem(16)};
  background-color: #c4e1e433;
  border-radius: 50px;
  display: inline-block;
`

function Shortcuts({ shortCutList, label }: Props) {
  return (
    <StyledRibbon>
      <StyledNav>
        {<StyledHeading level={2}>{label && label}</StyledHeading>}
        {shortCutList && shortCutList.length > 0 && (
          <StyledList>
            {shortCutList.map(
              (link, i) =>
                link &&
                link.label && (
                  <li key={`${i}-${link.label}`}>
                    <StyledShortcut {...link}>{link.label}</StyledShortcut>
                  </li>
                ),
            )}
          </StyledList>
        )}
      </StyledNav>
    </StyledRibbon>
  )
}

Shortcuts.defaultProps = {}
Shortcuts.displayName = 'Shortcuts'

export default Shortcuts
